import { observer } from "mobx-react-lite";
import * as React from "react";
import styled from "styled-components";
import { useRootStore } from "../../models/Root";
import images from "../../utils/assets/images";
import Modal from "./Modal";
import ModalBackground from "./ModalBackground";
import useStats from "../../utils/game/useStats";
import { stripIndent } from "common-tags";

const Container = styled.div`
  width: fit-content;

  h2 {
    text-transform: uppercase;
    font-size: 1.75rem;
  }

  max-width: 350px;
`;

const BackgroundBadge = styled.img`
  position: absolute;
  right: -106px;
  bottom: 15px;

  pointer-events: none;
  user-select: none;

  z-index: -1;
`;

const Contents = styled.div`
  position: relative;
  z-index: 1;
  padding: 2rem;
  overflow: hidden;
`;

const ContentsTop = styled(Contents)`
  min-height: 250px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2 {
    font-size: 2rem;
  }
`;

const ContentsBottom = styled(Contents)`
  margin-top: 1rem;

  h2 {
    margin-top: 0;
  }
`;

const BackButton = styled.button`
  position: absolute;
  top: 1rem;
  left: 1rem;

  padding: 0.5rem;
  border: 1px solid ${(props) => props.theme.colors.modal.text};
  background: transparent;
  border-radius: 999em;

  text-transform: uppercase;

  img {
    margin-right: 0.5rem;
  }
`;

const LetsGoButton = styled.a`
  display: block;
  background-color: ${(props) => props.theme.colors.shareModal.letsGoButtonBg};
  border-radius: 999em;

  width: 100%;
  border: none;
  padding: 1rem;
  text-transform: uppercase;
  text-decoration: none;

  color: white;
  font-size: 1.25rem;
  margin-top: 1.5rem;
`;

const Flash = styled.div`
  animation-name: flash;
  animation-duration: 300ms;
`;

const ScaleImage = styled.div`
  img {
    object-fit: contain;
    width: 50px;
  }
`;

const ShareModal = observer(() => {
  const root = useRootStore();

  const { score, revealedBombCount } = useStats();

  // Caved: 11/15/2023 💎
  // Total Moves: 32
  // Skulls Hit: 7 💀
  // https://www.puzzlesociety.com/caved

  React.useEffect(() => {
    navigator.clipboard.writeText(stripIndent`
      ${root.app.text.gameName}: ${root.app.issueDateDisplayTextShort} 💎
      ${root.app.text.shareClipboardTotalMoves}: ${score}
      ${root.app.text.shareClipboardSkullsHit}: ${revealedBombCount} 💀
      ${root.app.text.gameUrl}
    `);
  }, [
    score,
    revealedBombCount,
    root.app.text.gameName,
    root.app.text.gameUrl,
    root.app.text.shareClipboardTotalMoves,
    root.app.text.shareClipboardSkullsHit,
    root.app.issueDateDisplayTextShort,
  ]);

  return (
    <Modal>
      <Container>
        <ModalBackground>
          <ContentsTop>
            <BackButton onClick={() => root.app.modal.show("stats")}>
              <img src={images["ic_keyboard_arrow_left_24px.svg"]} />{" "}
              {root.app.text.shareModalBackButton}
            </BackButton>
            <ScaleImage>
              <Flash>
                <img src={images["Icon - Check Circle - default.svg"]} />
              </Flash>
            </ScaleImage>
            <h2>{root.app.text.shareModalTitle}</h2>
            <p>{root.app.text.shareModalBody}</p>
            <BackgroundBadge src={images["badge-gray.svg"]} />
          </ContentsTop>
        </ModalBackground>
        {root.app.settings.gameplay.showPlayWithFriendsModal ? (
          <ModalBackground>
            <ContentsBottom>
              <h2>{root.app.text.shareModalPlayWithFriendsTitle}</h2>
              <p>{root.app.text.shareModalPlayWithFriendsBody}</p>
              <LetsGoButton
                href={root.app.text.letsGoUrl}
                target="_blank"
                rel="noopener"
              >
                {root.app.text.shareModalPlayWithFriendsButton}
              </LetsGoButton>
            </ContentsBottom>
          </ModalBackground>
        ) : null}
      </Container>
    </Modal>
  );
});

export default ShareModal;
